import { IdName, idNames } from '../common/id-name';

export enum IAccountMePermissions {
  canViewPersonalDetailsPage = 1,
  canViewLimitsPage,
  canViewDocumentsPage,
  canViewSecurityPage,
  canViewDepositPage,
  canViewWithdrawalPage,
  canViewBonusesPage,
  canViewFinancialMovementsPage,
  canAccessMeArea = 156,
  canViewSportsBookTicketsPage = 157,
  canViewPromotionsPage = 158,
  canEditPersonalData = 170,
  canViewWithdrawalsHistoryPage = 321,
  canViewWithdrawalsHistory = 379,
  canViewWithdrawalsHistoryInShop = 380,
  canVoidWithdrawals = 381
}
export enum IAccountCashierPermissions {
  canViewDrawerPage = 334,
  canViewDrawerMovementPage = 368,
  canViewSportsbookTicketsRetailPage,
  canAddManualCashDeposit,
  canAddManualCashWithdrawal,
}


export namespace IAccountMePermissions {
  export function values(): Array<IdName<any>> {
    return idNames(IAccountMePermissions);
  }
}

export enum IAccountReportPermission {
  canAccessReportsArea = 159,
  canViewReportFinanceNetworkViewPage,
  canViewReportCashFlowNetworkViewPage,
  canViewReportBettingNetworkViewPage,
  canViewReportCasinoNetworkViewPage,
  canViewReportCasinoLiveNetworkViewPage,
  canViewReportPokerNetworkViewPage,
  canViewReportLotteryNetworkViewPage,
  canViewReportVirtualNetworkViewPage,
  canViewReportCashiersDrawersNetworkViewPage = 337,
  canViewReportCashiersDrawersPage = 338,
  canViewReportSportsBookNetworkViewPage = 367
}

export namespace IAccountReportPermission {
  export function values(): Array<IdName<any>> {
    return idNames(IAccountReportPermission);
  }
}

export enum IAccountNetworkPermission {
  canAccessNetworkArea = 168,
  canViewPlayersListPage = 9,
  canCreateNewPlayers,
  canViewPlayersDetails,
  hideSensitiveDataInPlayersDetails,
  camSearchPlayerExtraNetwork,
  canBlockPlayers,
  canResetPlayersPassword,
  canViewPlayersFinanceHistory,
  canViewPlayersSportsBookHistory,
  canViewAgentsListPage,
  canCreateNewAgents,
  canViewAgentsDetails,
  canBlockAgents,
  canResetAgentsPassword,
  canViewShopsListPage,
  canCreateNewShops,
  canViewShopsDetails,
  canBlockShops,
  canResetShopsPassword,
  allowNetworkTransfer,
  allowHandlingNetworkTransfer,
  allowOnlyNetworkTransferDeposit,
  canSearchExtraNetwork,
  canViewReferralLinksPage,
  canViewSearchNetworkPage,
  canViewNetworkTransfersPage,
  canSearchNetworkTransfersOnlyByCode= 331,
  canVoidShopRequest = 311,
  canNetworkTransferDirectDeposit,
  canNetworkTransferDirectWithdrawal,
  canNetworkTransferShopRequestChangeStateReadyToCollect,
  canNetworkTransferShopRequestChangeStatePaid,
  canNetworkTransferShopRequestChangeStateRefused,
  canViewCashiersListPage = 372
}

export namespace IAccountNetworkPermission {
  export function values(): Array<IdName<any>> {
    return idNames(IAccountReportPermission);
  }
}

export enum IOperatorPermission {
  canChangeOwnPassword = 34,
  canViewOwnSessionsPage = 41,
  canViewOwnActivitiesPage = 42,
  canEditOwnPersonalData = 43,
  canAddContacts = 44,
  canEditContacts = 45,
  canDeleteContacts = 46,
  canAddGeoInfo = 47,
  canViewStats = 48,
  canDeleteGeoInfo = 49,
  canViewPlayersPage = 50,
  canViewShopsPage = 51,
  canViewAgentsPage = 52,
  canViewBookmakersPage = 53,
  canViewOperatorsPage = 54,
  canViewDocumentsPage = 55,
  canViewMessagesPage = 56,
  canViewSessionsPage = 57,
  canViewSettingsTemplatePage = 58,
  canAddNewPlayers = 59,
  canChangePlayerPassword = 67,
  canChangePlayerStatus = 68,
  canChangePlayerParent = 69,
  canViewPlayerStatusHistory = 70,
  canViewPlayerParentHistory = 71,
  canEditPlayerPersonalData = 72,
  canImpersonatePlayer = 73,
  canAddNewAgent = 74,
  canChangeAgentPassword = 75,
  canChangeAgentStatus = 76,
  canChangeAgentParent = 77,
  canViewAgentStatusHistory = 78,
  canViewAgentParentHistory = 79,
  canEditAgentPersonalData = 80,
  canImpersonateAgent = 81,
  canAddNewShop = 83,
  canChangeShopPassword = 84,
  canChangeShopStatus = 85,
  canChangeShopParent = 86,
  canViewShopStatusHistory = 87,
  canViewShopParentHistory = 88,
  canEditShopPersonalData = 89,
  canImpersonateShop,
  canAddNewOperator = 93,
  canChangeOperatorPassword = 94,
  canChangeOperatorStatus,
  canEditOperatorPersonalData,
  canAddAccountSettingTemplate,
  canEditAccountSettingTemplate,
  canAddSportsBookSettingTemplate,
  canEditSportsBookSettingTemplate,
  canAddOperatorSettingTemplate,
  canEditOperatorSettingTemplate,
  canViewMovementsPage,
  canViewWithdawalsPage,
  canViewBonusesPage,
  canViewNetworkTransfersPage,
  canApproveOrRefuseWithdrawal,
  canPayWithdrawal,
  canAddManualBonus,
  canRefuseNetworkTransferShopPayoutRequest,
  canAddManualMovement = 112,
  canViewMenusPage,
  canViewTranslationsPage,
  canViewSocialsPage,
  canViewFootersPage,
  canViewLicensesPage,
  canAddMenuLink,
  canEditMenuLink,
  canEditMenu,
  canEditTranslations = 122,
  canEditSocials,
  canEditLicense,
  canViewPromotionsPage = 128,
  canAddPromotion,
  canEditPromotion,
  canViewTicketsPage = 131,
  canVoidTicket,
  canViewReportDashboard,
  canViewReportsBI,
  canViewFinanceNetworkOverview,
  canViewCashFlowNetworkOverView,
  canViewBettingNetworkOverView,
  canViewCasinoNetworkOverView,
  canViewCasinoLiveNetworkOverView,
  canViewPokersNetworkOverView,
  canViewLotteriesNetworkOverView,
  canViewVirtualsNetworkOverView,
  mainReport,
  accountBalances,
  allCustomers,
  allRegisteredNotDeposited,
  depositAndWithdrawal,
  firstDeposit,
  newCustomer,
  casinoOverview,
  lotteriesOverview,
  virtualsOverview,
  manualTicketsSettlement,
  settlement,
  bettingOverviewTickets,
  canViewActivitiesPage = 171,
  canAddManualMovementWithdrawal = 172,
  canAddAPaymentsMethodsSettingTemplate,
  canEditAPaymentsMethodsSettingTemplate,
  canAddAEarningsSettingTemplate,
  canEditAEarningsSettingTemplate,
  canViewCashiersPage = 328,
  canAddNewCashier = 329,
  canImpersonateCashier = 332,
  canRollBackTicket = 335,
  canChangeCashierPassword = 336,
  canViewSlidersPage,
  canViewFaqsPage,
  canViewKraPage = 500
}

export namespace IOperatorPermission {
  export function values(): Array<IdName<any>> {
    return idNames(IOperatorPermission);
  }
}
